import { debounce, downloadFileByPath } from './../../../utils/index';
import { MessageBoxData, MessageBoxInputData } from 'element-ui/types/message-box';
import { dateFormat, showWarningConfirm, showWarningPrompt } from '@/utils/index';
import { Query } from '@/api/base';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTable, OsQueryPlan } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { PagingMixin } from '@/mixins/paging';
import { ProductionOrderResource } from '@/resource/model/production-management/production-order';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { ProductionOrderStatusEnum } from '@/resource/enum/production-order-status';
import { productionOrderService, customerService, minioService } from '@/api';
import { messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { PageQueryPlanEnum, ProductionOrderTypeEnum } from '@/resource/enum';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';

@Component({
  name: 'ProductionOrder',
  components: {}
})
export default class ProductionOrder extends mixins(PagingMixin, CustomColumnMixin) {
  /**
   * 表格显示模式标识
   */
  public viewRadio = 1;

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<ProductionOrderResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true
    // defaultSort: { prop: 'createTime', order: 'descending' }
  };

  public tableToolbarOptions: Array<OperationOption> = [];

  /**
   * 查询条件UI配置
   */
  public queryFormOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'statusList',
      label: 'order.orderStatus',
      option: {
        placeholder: 'order.selectStatus',
        multiple: true
      },
      optionData: productionOrderService.getStatusSelectableList()
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'order.customer',
      option: {
        placeholder: 'order.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'common.createUserName',
      option: {
        placeholder: 'common.inputCreateUserName'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'createDate',
      label: 'order.createDate',
      option: {
        startPlaceholder: 'order.startPlaceholder',
        endPlaceholder: 'order.endPlaceholder',
        placeholder: 'common.inputCreateDate',
        pickerOptions: {
          disabledDate(callbackDateStr: string): Boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();

            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  /**
   * 查询表单对象
   */
  public queryForm: Query<{
    keywords: string;
    statusList: Array<Number>;
    companyId: Number;
    customerId: Number;
    createUserName: string;
    createDate: Array<string>;
    startDate: string;
    endDate: string;
  }> = {
    companyId: undefined,
    createDate: [],
    createUserName: '',
    customerId: undefined,
    endDate: undefined,
    keywords: '',
    startDate: undefined,
    statusList: []
  };
  public defaultQueryForm: Query<{
    keywords: string;
    statusList: Array<Number>;
    companyId: Number;
    customerId: Number;
    createUserName: string;
    createDate: Array<string>;
    startDate: string;
    endDate: string;
  }> = {
    companyId: undefined,
    createDate: [],
    createUserName: '',
    customerId: undefined,
    endDate: undefined,
    keywords: '',
    startDate: undefined,
    statusList: []
  };

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<ProductionOrderResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true,
      align: 'center'
    },
    {
      prop: 'code',
      label: 'order.code',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'projectName',
      label: 'order.projectName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'order.customerName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'order.requiredDeliveryTime',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredArriveTime',
      label: 'order.requiredArriveTime',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'makeFile',
      label: 'order.downloadFile',
      minWidth: '180',
      showOverflowTooltip: true
    },
    {
      prop: 'total',
      label: 'projectProduct.count',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'area',
      label: 'order.totalArea',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'progress',
      label: 'order.progress',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'orderTakingUserName',
      label: 'order.acceptUserName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'orderFinishTime',
      label: 'order.finishTime',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'order.createUserName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'order.createTime',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOption: RowOperation<ProductionOrderResource> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'accept',
        type: 'text',
        label: 'button.accept',
        icon: 'el-icon-check',
        permissionCode: 'production:order:orderTaking',
        handleClick: (row: ProductionOrderResource): void => {
          this.accept(row);
        },
        dynamicHidden: (rowData: ProductionOrderResource): boolean => {
          return Number(rowData.status) !== ProductionOrderStatusEnum.waitingAccept;
        }
      },
      {
        operationType: 'reject',
        type: 'text',
        label: 'button.reject',
        icon: 'el-icon-close',
        permissionCode: 'production:order:refuse',
        handleClick: (row: ProductionOrderResource): void => {
          this.reject(row);
        },
        dynamicHidden: (rowData: ProductionOrderResource): boolean => {
          return Number(rowData.status) !== ProductionOrderStatusEnum.waitingAccept;
        }
      },
      {
        operationType: 'cancel',
        type: 'text',
        label: 'order.cancel',
        icon: 'el-icon-back',
        permissionCode: 'production:order:cancel',
        handleClick: (row: ProductionOrderResource): void => {
          this.handleCancel(row);
        },
        dynamicHidden: (rowData: ProductionOrderResource): boolean => {
          return !this.canCancel(rowData);
        }
      },
      {
        operationType: 'confirm',
        type: 'text',
        label: 'order.confirmProduction',
        icon: 'el-icon-check',
        permissionCode: 'production:order:scheduling',
        handleClick: (row: ProductionOrderResource): void => {
          this.confirmProduction(row);
        },
        dynamicHidden: (rowData: ProductionOrderResource): boolean => {
          return !(Number(rowData.status) === ProductionOrderStatusEnum.waitingPlan);
        }
      },
      {
        operationType: 'confirm',
        type: 'text',
        label: 'order.submitComplete',
        icon: 'el-icon-finished',
        permissionCode: 'production:order:finish',
        handleClick: (row: ProductionOrderResource): void => {
          this.submitComplete(row);
        },
        dynamicHidden: (rowData: ProductionOrderResource): boolean => {
          return !this.assertCanFinish(rowData);
        }
      }
    ]
  };

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.productionOrder;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];

  /**
   * 选中数据缓存对象
   */
  private selectedRows: Array<ProductionOrderResource> = [];

  public activated(): void {
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }

  public created(): void {
    this.initCustomSelectableList();
    this.initColumns(this.tableColumnOptions, 'productionOrder');
  }

  /**
   * 重新加载数据
   */
  public reloadData(): void {
    this.paging.currentPage = 1;
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    (this.$refs.tableRef as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }
  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }
  /**
   * 去订单详情
   * @param rowData 行数据
   */
  public handleGoDetail(rowData: ProductionOrderResource): void {
    this.$router.push({
      path: '/production-order-detail',
      query: {
        orderId: rowData.id.toString()
      }
    });
  }

  /**
   * 判断单据是否可完成
   * @param order 生产订单
   * @returns
   */
  public assertCanFinish(order: ProductionOrderResource): boolean {
    return (
      order.status === ProductionOrderStatusEnum.inProgress &&
      (order.total === order.finishNum || order.type === ProductionOrderTypeEnum.offline)
    );
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: ProductionOrderStatusEnum): string {
    return productionOrderService.getStatusI18Key(status);
  }

  /**
   * 根据状态获取class
   * @param status  状态值
   * @returns
   */
  public getStatusClass(status: ProductionOrderStatusEnum): string {
    return productionOrderService.getStatusClass(status);
  }

  /**
   * 根据状态获取状态样式类型
   * @param status 状态
   * @returns
   */
  public getStatusType(status: ProductionOrderStatusEnum): string {
    return productionOrderService.getStatusType(status);
  }

  /**
   * 分页切换回调
   */
  public pagingData(): void {
    this.loadData();
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<ProductionOrderResource>): void {
    this.selectedRows = selectedData;
  }

  /**
   * 接受
   * @param id ID
   */
  public accept(rowData: ProductionOrderResource): void {
    showWarningConfirm(translation('order.acceptTip'))
      .then(async () => {
        try {
          await productionOrderService.accept(rowData.id);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error as any);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 拒绝
   * @param rowData 订单对象
   */
  public reject(rowData: ProductionOrderResource): void {
    showWarningPrompt(translation('order.rejectTip'), translation('order.inputReason'), {
      inputErrorMessage: translation('order.inputReason')
    })
      .then(async (reason: MessageBoxData) => {
        try {
          await productionOrderService.reject(rowData.id, (reason as MessageBoxInputData).value);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error as any);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 判断是否可以取消接单
   * @param rowData 订单对象
   * @return boolean
   */
  public canCancel(rowData: ProductionOrderResource): boolean {
    return rowData.status === 3 || rowData.status === 4;
  }

  /**
   * 取消接单
   * @param rowData 订单对象
   */
  public handleCancel(rowData: ProductionOrderResource): void {
    showWarningConfirm(translation('order.cancelTip'))
      .then(async () => {
        try {
          await productionOrderService.cancel(rowData.id);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error as any);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 提交完成
   */
  public submitComplete(rowData: ProductionOrderResource): void {
    if (rowData.status !== ProductionOrderStatusEnum.inProgress) {
      Message.warning(translation('order.selectInProgressTip'));
      return;
    }

    showWarningConfirm(translation('order.submitCompleteTip'))
      .then(async () => {
        try {
          await productionOrderService.submitComplete(rowData.id);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error as any);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 确认排产
   * @param rowData 订单数据对象
   */
  public confirmProduction(rowData: ProductionOrderResource): void {
    showWarningConfirm(translation('order.confirmProductionTip'))
      .then(async () => {
        try {
          await productionOrderService.confirmProduction(rowData.id);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error as any);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 下载文件
   */
  public async download(fileName: string, filePath: string): Promise<void> {
    try {
      await minioService.init();
      const downloadPath = await minioService.getDownLoadPath(filePath);
      downloadFileByPath(fileName, downloadPath);
    } catch (error) {
      messageError(error as any);
    }
  }

  @Watch('queryForm.createDate')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.startDate = undefined;
      this.queryForm.endDate = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.startDate = dateFormat(value[0]);
      this.queryForm.endDate = `${dateFormat(value[1], 'YYYY-MM-DD')} 23:59:59`;
    }
  }

  /**
   * 数据加载
   */
  private loadData(): void {
    this.tableOption.loading = true;
    productionOrderService
      .getList(this.queryForm as ProductionOrderResource, this.paging)
      .then(res => {
        res.data.forEach((item: any) => {
          if (item.type === 2) {
            let lastSplitIndex = item.makeFile.lastIndexOf('/') + 1;
            item.makeFileName = item.makeFile.substring(lastSplitIndex);

            lastSplitIndex = item.detailFile.lastIndexOf('/') + 1;
            item.detailFileName = item.detailFile.substring(lastSplitIndex);
          }
        });

        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 初始化当前用户可选客户列表
   */
  private async initCustomSelectableList(): Promise<void> {
    const list: any = await customerService.getAllUsingCustomer();

    const selectableList: Array<any> = [];
    list.forEach((item: any) => {
      selectableList.push({
        label: item.customerName,
        value: item.customerId
      });
    });

    const option = this.queryFormOption.find(item => item.field === 'customerId');

    option!.optionData = selectableList;
  }
}
